import { useState,  useLayoutEffect,useEffect } from "react";
import { CreatePrimaryToken, CreateSecondaryToken, getInfo, GetJustWhereUsers, GetVisitLabelByToken } from "../utilities/calls";
import { useNavigate,useParams } from "react-router-dom";
import { AiOutlineImport, AiFillCheckCircle} from "react-icons/ai";
import { sanitizeEmail} from "../utilities/functions"
import { FaLink } from "react-icons/fa";
import QRCodeModal from "./QRCodeModal";

export default function OfferAddress() {
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const { addressId } = useParams();
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('');
    const [confirmationMessage,setConfirmationMessage] = useState(false);
    const [suggestedEmail,setSuggestedEmail] = useState('');
    const [sendBtn,setSendBtn] = useState(true)
    const [contineAndSendBtn,setContinueAndSentBtn] = useState(false);   
    const [isModalOpen, setModalOpen] = useState(false);
    const [visitLink, setVisitLink] = useState();
    const [qrcode, setQrcode] = useState();
    const handleOpenModal = () => {
	  if (visitLink) {
		setQrcode(visitLink);
		setModalOpen(true);
	  }
    }
    const handleCloseModal = () => setModalOpen(false);
    
    useLayoutEffect(() => {
    }, []);

    const offerAddress = () => {
        const individualid = getInfo("IndividualID");
        GetJustWhereUsers([userEmail]).then((resp) => {
          if(resp.validUserIds?.length === 0){
            const updatedUserEmail=sanitizeEmail(userEmail)
            GetJustWhereUsers([updatedUserEmail]).then((resp)=>{
               if(resp.validUserIds?.length > 0) {
                  setSuggestedEmail(resp.validUserIds[0])
                setConfirmationMessage(true)
                setContinueAndSentBtn(true)
                setSendBtn(false)
              }
              else {
                setShowAlert(true);
                setAlertText(userEmail + " is not a JustWhere user, you can only offer address to existing JustWhere User.");
            }
            }).catch((err) => {
              console.log(err);
              setShowAlert(true);
              setAlertText("Error occurred while verifiying justwhere user,please continue after some time");
            })
          }else 
            {
                CreatePrimaryToken("",addressId, individualid).then((resp) => {
                  CreateSecondaryToken("", userEmail, resp.token).then((resp) => {
                    GetVisitLabelByToken(resp.token).then((resp) => {
                      setVisitLink(process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/#/visitlink/' + resp);
                    }
                     ).catch((err) => console.log('err',err));
                    setSendBtn(false)
                  }).catch((err)=> { console.log(err) 
                    setAlertText(err?.response?.data);
                    setShowAlert(true);
                  })
                }).catch((err)=> { console.log(err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                 })
            }
    }).catch((err) => {console.log(err)})
            
    }
    

    const cancelFn = () => {
       navigate(-1);
    }

    const handleInputChange = (event) => {
      setUserEmail(event.target.value)
    };
    const offerAddressWithSuggestedEmail=()=>{
    const individualid = getInfo("IndividualID");
    CreatePrimaryToken("",addressId, individualid).then((resp) => {
    CreateSecondaryToken("", suggestedEmail, resp.token).then((resp) => {
      GetVisitLabelByToken(resp.token).then((resp) => {
       setVisitLink(process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/#/visitlink/' + resp);
      }
      ).catch((err) => console.log('err',err));
      //setShowAlert(true);
      //setAlertText("Address shared with " + suggestedEmail);
      setSendBtn(false)

    }).catch((err)=> console.log(err))
  }).catch((err)=> { console.log(err);
      setAlertText("Error contacting Service Provider, please try again!");
      setShowAlert(true)
   })
   setConfirmationMessage(false)
   setContinueAndSentBtn(false)
   }
    return <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div className="mx-auto max-w-screen-lg form-container p-5">
            <div>
                <h1 className="form-label py-2">
                    <AiOutlineImport className="inline-icon" />
                    Send Address 
                </h1>
            </div>

            <div className="grid grid-cols-1">

                
            <div className="col-span-12 form-input px-2 py-2">
            <input
            type="text"
            value={userEmail}
            onChange={handleInputChange}
            placeholder="Enter Email"
            className="col-span-12 form-input px-2 py-2"
      />
            </div>
                <div className="flex flex-col gap-2">
                {
                    confirmationMessage &&
                    <div className="flex flex-col gap-2"> 
                    <p className="text-sm font-medium text-orange-400">User with email "{userEmail}" is not registered with us but <span className="text-blue-500 hover:underline">"{suggestedEmail}"</span>{" "}is a JustWhere User.</p>
                    <p className="text-sm font-medium text-orange-400">Click on continue and send if you wish to send address to <span className="text-blue-500 hover:underline">"{suggestedEmail}"</span></p>
                    </div>
                  }
                <div class="flex justify-start items-start space-x-4 py-2 ">
                  {
                    contineAndSendBtn && 
                      <button onClick={offerAddressWithSuggestedEmail} type="submit" className="button-style positive-action">
                        Continue And Send
                    </button>
                    }
                    {sendBtn && 
                    <button onClick={offerAddress} type="submit" className="button-style positive-action">
                        Send
                    </button>
                    }
                    {sendBtn === true || contineAndSendBtn === true ? (
                        <button onClick={cancelFn} className="button-style">Cancel</button>
                    ) : (
                        <button onClick={cancelFn} className="button-style">Close</button>
                    )}
                    </div>
                    <div
                        class=" w-full bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                        role="alert"
                        style={{ display: showAlert ? "block" : "none" }}
                    >
                        <span class="text-sm">{alertText} </span>
                      </div>
                    <div class=" w-full">  
                    { visitLink ? <p>Address shared with {suggestedEmail ? suggestedEmail : userEmail} successfully.  
                                             <button onClick={() => {navigator.clipboard.writeText(visitLink); document.getElementById("checked").style.display="";}} 
                                            		className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline font-bold">
													<FaLink className="inline-icon"/>Copy Share Link
											</button>
											<span className="text-sm text-green-300" id="checked" style={{display: "none"}} >
											   <AiFillCheckCircle color="green" style={{"padding-left":"0.1rem"}} className=" inline-icon"/>Copied to clipboard.
											</span>
											<p class="mt-1 italic text-gray-200 text-xs"> The link is valid for 24 hours</p>
											<button className="mt-1 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-blue-500" onClick={handleOpenModal}>Show QR Code</button>
											 {isModalOpen && (
										        <QRCodeModal
										          isOpen={isModalOpen}
										          onClose={handleCloseModal}
										          qrValue={qrcode}
										        />
										      )}
										</p>                  
                       : <></>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}