//param title, close and save handles, locales
import { AiOutlineProfile } from "react-icons/ai";
import { UpdateAddress, GetAddress, getInfo } from "../utilities/calls";
import React, { useState, useEffect } from "react";
import { useParams, Link,useLocation } from "react-router-dom";
import { address_locales, countryLocaleList } from "../utilities/templates";
import { renderField } from "../utilities/functions";
import Tags from "./Tags";
import CopyToClipboard from "./CopyToClipboard";


export default function AddressDetailsForm() {
  const [sent, setSent] = useState(true);
 
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();

  const [selectedCountry, setSelectedCountry] = useState(
    Intl.NumberFormat().resolvedOptions().locale
  );

  const [updatedaddress, updateAddress] = useState({
    name: "",
    addressee: "",
    street: "",
    city: "",
    zipcode: "",
    state: "",
    phone: "",
    email: "",
    country: "",
  });

  const { id } = useParams(); // Get the address ID from the route parameter
  const [address, setAddress] = useState({});
  const [customTags,setCustomTags] = useState([])
  const location=useLocation()
  const previousPage = location.state?.previousPage || '/address-list';


  const processForm = () => {
    var mergedaddress = {
      street: updatedaddress.street,
      city: updatedaddress.city,
      state: updatedaddress.state,
      zipCode: updatedaddress.zipCode,
      country: updatedaddress.country,
      addressee: updatedaddress.addressee,
      phone: updatedaddress.phone,
      email: updatedaddress.email,
      tags: {
        atag: {
          Name: updatedaddress.name,
          Value: null,
          Private: false,
          Required: false,
          Editable: false,
        },
      },
    };
    mergedaddress.tags = {...mergedaddress.tags, ...customTags}

    const individualid = getInfo("IndividualID");
    const ownertoken = getInfo("Ownertoken");
    UpdateAddress(id, "", ownertoken, individualid, mergedaddress)
      .then((response) => {
        console.log("done updating address");
        setShowAlert(true);
        setAlertText("Address was updated!");
        setSent(true);
        // Set the retrieved address details in the state
      })
      .catch((error) => {
        console.error("Error UPDATING address details:", error);
        setAlertText("Error updating the address, please try again!");
        setShowAlert(true)
      });
  };

  useEffect(() => {
    const individualid = getInfo("IndividualID");
    const ownertoken = getInfo("Ownertoken");
    // Define the API endpoint to fetch the address details (replace with your actual API endpoint)
    GetAddress(id, "", ownertoken, individualid)
      .then((response) => {
        // Set the retrieved address details in the state
        setAddress(response);
        const countryVal = countryLocaleList.find((o) => o.name === response.country).value;
        setSelectedCountry(countryVal);   
      })
      .catch((error) => {
        console.error("Error fetching address details:", error);
        setAlertText("Error fetching address details, please try again!");
        setShowAlert(true)
      });
  }, [id]);

  useEffect(() => {
    updateAddress({
      addressee: address.addressee,
      street: address.street,
      city: address.city,
      zipCode: address.zipCode,
      state: address.state,
      phone: address.phone,
      email: address.email,
      country: address.country,
      name: address?.tags?.atag?.Name,
    });
    var keys = Object.keys(updatedaddress).map((key) => { return key.toLowerCase()} );
    let tags = {};
    console.log(address.tags)
    if(address.tags !== undefined) {
    Object.keys(address.tags).map((tag) => {
      var name = address.tags[tag].Name;
      if(keys.indexOf(name.toLowerCase()) === -1 && tag.localeCompare("atag") !== 0)
        tags[name] = address.tags[tag];
    })
    }
    setCustomTags(tags);

  }, [address]);

  const handleInputChange = (e) => {
    if (e.target.name === "country") {
      handleCountryChange(e);
      return;
    }
    const { name, value } = e.target;
    updateAddress((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setSent(false);
  };

  const handleCountryChange = (e) => {
    const countryVal = countryLocaleList.find(
      (o) => o.name === e.target.value
    ).value;
    updateAddress((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
    setSent(false);

    setSelectedCountry(countryVal);
  };

  const addCustomTag = (tag, key) => {
    var tagname = key ? key : tag.Name;
    setCustomTags((prevValues) => ({
      ...prevValues, [tagname] : tag
    }))
    setSent(false);
  } 

  const removeCustomTag = (tagName) => {
    setCustomTags((prevValues) => { delete prevValues[tagName]; return prevValues;})
    setSent(false);
  }


  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="py-2 form-label">
            <AiOutlineProfile className="inline-icon" />
            Address Details
          </h1>
          <h1 id="addrId">{id}</h1><CopyToClipboard elId="addrId"/>
        </div>

        <div>
          {address_locales[selectedCountry]
            ? address_locales[selectedCountry].map((element) => {
                return renderField(element.label, element.id,element.type, updatedaddress[element.id], handleInputChange,element.options);
              })
            : address_locales["default"].map((element) => {
                return renderField(element.label,element.id,element.type,updatedaddress[element.id],handleInputChange,element.options);
              })}
        </div>
        <div className="py-5"><Tags saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag}/></div>  

        <section className="grid grid-cols-12 gap-20 py-2">
          <button style={{width: "fit-content",display: sent ? "none" : "block",}} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="button-style form-button positive-action"
          >
            SAVE
          </button>

          {!sent ? (
            <Link to={previousPage} state={{"contenttype" : location.state.contenttype}}>
              <button className="button-style">Cancel</button>
            </Link>
          ) : (
            <Link to={previousPage} state={{"contenttype" : location.state.contenttype}}>
              <button className="button-style">Close</button>
            </Link>
          )}
          <div
            class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
            role="alert"
            style={{ display: showAlert ? "block" : "none" }}
          >
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
      </div>
    </div>
  );
}

