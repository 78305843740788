import axios from 'axios';

import { isAfter } from 'date-fns';

let abortController;

// Fetch user info function
function fetchUserInfo() {

    const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/userinfo';
    
    let authheader = sessionStorage.getItem('JWAUTH');
    
    const xhr = new XMLHttpRequest();
    xhr.open("GET", apiUrl, false); // false makes the request synchronous
	xhr.setRequestHeader('Authorization', authheader);
    try {
        xhr.withCredentials = true;
        xhr.send();
        let userInfo = { data: null, validTill: null };
        
        if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);

            userInfo.data = data;
            userInfo.validTill = data.ValidTill;

        }
        
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        return userInfo
            
    } catch (error) {
        console.error('Error fetching userInfo:', error);
    }
};


function getUserInfo() {
	
	let userInfo = { data: null, validTill: null };
	if(sessionStorage.getItem('JWAUTH')) {
		let storedUserInfo = sessionStorage.getItem('userInfo');
    	if (storedUserInfo) {
        	userInfo = JSON.parse(storedUserInfo);
    	}
    	if (userInfo.validTill != null && !isAfter(new Date(), new Date(userInfo.validTill))) {
        // Cache is valid, return cached data
        return userInfo.data;
    	}
    	userInfo = fetchUserInfo();
	}	
    return userInfo.data;
};


const SetupInterceptor =  async (navigate) => {

	axios.interceptors.request.use(
		(config) => {
			if(sessionStorage.getItem('JWAUTH') !== undefined){
				if(!config?.url?.includes("people.googleapis.com"))
					config.headers['Authorization'] = sessionStorage.getItem('JWAUTH'); 
			}
			document.getElementById("loadingIcon").style.display = "inline-block";
			if(!abortController) { abortController = new AbortController()}
			const newConfig = {...config, signal: abortController.signal}
			if(getInfo("IndividualID") == null && !config?.url?.includes("login")) {
				abortController.abort();
				abortController = new AbortController();
			}
			return newConfig;
		},
        (error) => { 
        		console.log("error");
	        document.getElementById("loadingIcon").style.display = "none";
			return Promise.reject(error); 
		}
	)

	axios.interceptors.response.use(
		(response) => {		
			let jwauth = response.headers.get('Authorization')	;
			if(jwauth !== undefined)
				sessionStorage.setItem('JWAUTH', jwauth);
			document.getElementById("loadingIcon").style.display = "none";
			let redirectto = sessionStorage.getItem('redirectto');
			if(redirectto) {
				navigate(redirectto)
				sessionStorage.removeItem('redirectto');
			}
		return response},
		(error) => { 					document.getElementById("loadingIcon").style.display = "none";
		console.log('Response Interceptor Error: ', error, error.name); 
		if(error.code === "ERR_CANCELED" || error.name === "CanceledError"){
			navigate("/login", {state : {error: "Please login to view this page."}});
		}
		if(error?.response && error?.response?.status === 401) {localStorage.setItem(document.location.hostname + '.loginStatus', "LoggedOut" ); sessionStorage.removeItem("JWAUTH"); navigate("/login", {state: {error: "You are not logged in, please login to continue."}})}
		if(error?.config?.url?.includes("login")) {navigate("/login",{state: {error: "The login was not successful, please try again."}})}
		return Promise.reject(error);
		}
	)
}

const GetShares = async (individualId ) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/records/Individual';
	try {
	  const response = await axios.get(`${apiUrl}/${individualId}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching individualId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetSharesWith = async (beneficiaryId) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/records/Beneficiary';
	try {
	  const response = await axios.get(`${apiUrl}/${beneficiaryId}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching records for beneficiaryId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetSharesWithServiceProvider = async (spId) =>{
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/records/ServiceProvider';
	try {
	  const response = await axios.get(`${apiUrl}/${spId}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching records for beneficiaryId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const GetSentRequestsFrom = async (individualId,short ) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests/individual/from/' + individualId ;
	apiUrl += short ? '?short=true' : '';
	try {
	  const response = await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching individualId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetRequestDetails = async (requestid ) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests';
	try {
	  const response = await axios.get(`${apiUrl}/${requestid}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching individualId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const UpdateRequest = async (request) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests';
	try {
	  const response = await axios.put(`${apiUrl}/${request.id}`, request, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching individualId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const DeleteRequest = async (requestid ) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests';
	try {
	  const response = await axios.delete(`${apiUrl}/${requestid}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error deleting request:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetReceivedRequestsFor = async (individualId, short ) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests/individual/to/' + individualId ;
	apiUrl += short ? '?short=true' : '';
	try {
	  const response = await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching individualId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const CreateAddressRequestTo = async (fromIndividialid, toIndividualemail, tags, requesttype ) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests/individual';
	const requestbody = {
		requestType : requesttype || 'SHARE_ADDRESS', 
		status : 'NEW',
		addressID : '',
		tags : tags
	};
	try {
	  const response = await axios.post(`${apiUrl}/${fromIndividialid}/individual/${toIndividualemail}`,JSON.stringify(requestbody), { withCredentials: true });
	  if(response.status === 202) {
		return {
			redirectTo: response.data["redirectTo"],
			data: null
		}
	  }
	  return {			
		redirectTo: null,
	  	data: response.data
		};
	} catch (error) {
	  console.error('Error in calls.CreateAddressRequestTo:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetSharesForAddress = async (addressId ) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/records/Address';
	try {
	  const response = await axios.get(`${apiUrl}/${addressId}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching addressId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetAddress = async (addressId, beneficiaryId, beneficiary_token, individualId ) => {
  const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/addresses';
  try {
    const response = await axios.get(`${apiUrl}/${addressId}?beneficiaryId=${beneficiaryId}&beneficiary_token=${beneficiary_token}&individualId=${individualId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error fetching addressId:', error);
    throw error; // You might want to handle the error differently in your application
  }
};

const UpdateAddress = async (addressId, beneficiaryId, beneficiary_token, individualId , newaddress) => {
  const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/addresses';
  try {
	newaddress.individualId = individualId;
    const response = await axios.put(`${apiUrl}/${addressId}?beneficiaryId=${beneficiaryId}&beneficiary_token=${beneficiary_token}&individualId=${individualId}`, newaddress, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error fetching addressId:', error);
    throw error; // You might want to handle the error differently in your application
  }
};
const AddAddress = async (individualId , individualtags, newaddress) => {
  const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/individuals';
  
  try {
	newaddress.individualId = individualId;
	var individualInput = {
		newaddresses : [ newaddress ],
		tags : individualtags
	};
	
    const response = await axios.put(`${apiUrl}/${individualId}`, JSON.stringify(individualInput), { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error adding address', error);
    throw error; // You might want to handle the error differently in your application
  }
};

const DeleteAddress = async (addressId, individualId, beneficiary_token) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/addresses';
	try {
		const response = await axios.delete(`${apiUrl}/${addressId}?beneficiary_token=${beneficiary_token}&individualId=${individualId}`, { withCredentials: true });
		return response.data;
	  } catch (error) {
		console.error('Error deleting address:', error);
		throw error; // You might want to handle the error differently in your application
	  } 
}

const FetchIndividual = async (individualId) => {
  const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/individuals';
  try {
    const response = await axios.get(`${apiUrl}/${individualId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error fetching individual:', error);
    throw error; // You might want to handle the error differently in your application
  }
};

// "UserID","IndividualID","Ownertoken","Jwtoken"
const getInfo = (name) => {
  const ui = getUserInfo();
  if(name === "Gmailaccesstoken" && ui && ui[name] === "") {
	let userinfo = fetchUserInfo();
	if(userinfo && userinfo.data && userinfo.data[name])
		return userinfo.data[name];
  }
  return ui ? ui[name] : null;
};

function ConvertUnixTimestampToDateString(timestamp) {
  // Convert the timestamp string by X 1000 to make it milli
  var date = new Date(timestamp * 1000);

  return date.toString();
};

const GetSecondaryTokenForRequest = async (requestid) => {
  const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/tokens/secondary/request';
  try {
    const response = await axios.get(`${apiUrl}/${requestid}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error fetching secondary token:', error);
    throw error; // You might want to handle the error differently in your application
  }
};

const DeleteSecondaryToken = async (serviceProviderID, beneficiaryID, token) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/tokens/secondary/remove';
	const requestbody = {
		"serviceProviderID" : serviceProviderID, 
		"beneficiaryID" : beneficiaryID,
		"token" : token
	};
	try {
		const response = await axios.post(`${apiUrl}`, requestbody , { withCredentials: true });
		return response.data;
	} catch (error) {
		console.error('Error deleting secondary token:', error);
		throw error; // You might want to handle the error differently in your application
	} 	
}

const DeletePrimaryToken = async (serviceProviderID, individualID , addressId, securedcontentID) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/tokens/primary/remove';
	const requestbody = {
		"serviceProviderID" : serviceProviderID, 
		"addressID" : addressId,
		"individualID" : individualID,
		"securedcontentID" : securedcontentID
	};
	try {
		const response = await axios.post(`${apiUrl}`, requestbody , { withCredentials: true });
		return response.data;
	} catch (error) {
		console.error('Error deleting primary token:', error);
		throw error; // You might want to handle the error differently in your application
	} 	
}

const CreatePrimaryToken = async(serviceProviderID, addressId, individualId, securedcontentID) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/tokens/primary';
	const requestbody = {
		"serviceProviderID" : serviceProviderID, 
		"addressID" : addressId,
		"individualID" : individualId,
		"securedcontentID" : securedcontentID
	};
	try {
		const response = await axios.post(`${apiUrl}`,requestbody, { withCredentials: true });
		return response.data;
	} catch (error) {
		console.error('Error deleting secondary token:', error);
		throw error; // You might want to handle the error differently in your application
	} 	
}

const CreateSecondaryToken = async(serviceProviderID, beneficiaryID,token) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/tokens/secondary';
	const requestbody = {
		"serviceProviderID" : serviceProviderID, 
		"beneficiaryID" : beneficiaryID,
		"token" : token
	};
	try {
		const response = await axios.post(`${apiUrl}`,requestbody, { withCredentials: true });
		return response.data;
	} catch (error) {
		console.error('Error creating secondary token:', error);
		throw error; // You might want to handle the error differently in your application
	} 	
}
 

const GetAccounts = async () => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/accounts';
	try {
	  const response = await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching accounts:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const CreateAccount = async (id, tags, serviceproviders, beneficiaries) => {
	
	const apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/accounts';
	try {
		const obj = {
			"ID": id, 
			"tags": tags,
			"ServiceProviders": serviceproviders,
			"Beneficiaries": beneficiaries,
			"created": new Date().getTime(),
			"updated": new Date().getTime()
		  }
		const response = await axios.post(`${apiUrl}`, JSON.stringify(obj), { withCredentials: true });
		return response.data;
	
	} catch(error) {
		console.error('Error creating account:', error);
		throw error;
	}
}

const GetAccount = async(accountId) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/accounts';
	try {
	  const response =  await axios.get(`${apiUrl}/${accountId}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching accounts:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const DeleteAccount = async(accountId) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/accounts';
	try {
	  const response =  await axios.delete(`${apiUrl}/${accountId}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error deleting accounts:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}  

const UpdateAccount = async (accountId, individualId, tags, serviceproviders, beneficiaries) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/accounts';
	try {
		const obj = {
			"ID": individualId,
			"tags": tags,
			"ServiceProviders": serviceproviders,
			"Beneficiaries": beneficiaries,
			"created": new Date().getTime(),
			"updated": new Date().getTime()
		  }
		const response = await axios.put(`${apiUrl}/${accountId}`, JSON.stringify(obj), { withCredentials: true });
		return response.data;
	
	} catch(error) {
		console.error('Error creating account:', error);
		throw error;
	}
}

const GetServiceProvider = async(spId, isPrivate) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/serviceproviders/';
	apiUrl += spId;
	if(isPrivate) apiUrl += `?private=${isPrivate}`;

	try {
	  const response =  await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching accounts:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const UpdateServiceProvider = async(serviceProviderId, serviceProvider, isPrivate) => {
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/serviceproviders/';
	apiUrl += serviceProviderId;
	if(isPrivate) apiUrl += `?private=${isPrivate}`;

	try {
		const response =  await axios.put(`${apiUrl}`, JSON.stringify(serviceProvider), { withCredentials: true });
		return response.data;
	  } catch (error) {
		console.error('Error fetching addressId:', error);
		throw error; // You might want to handle the error differently in your application
	  }
}

const DeleteServiceProvider = async(spId, isPrivate) => {
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/serviceproviders/';
	apiUrl += spId;
	if(isPrivate) apiUrl += `?private=${isPrivate}`;
	try {
	  const response = await axios.delete(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error deleting service provider:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const DeleteBeneficiary = async(benId) => {
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/beneficiaries/';
	apiUrl += benId;

	try {
	  const response = await axios.delete(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error deleting sbeneficiary:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const GetBeneficiary = async(spId) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/beneficiaries';
	try {
	  const response =  await axios.get(`${apiUrl}/${spId}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching accounts:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const UpdateBeneficiary = async(serviceProviderId, serviceProvider) => {
	const apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/beneficiaries';
	try {
	
		const response =  await axios.put(`${apiUrl}/${serviceProviderId}`, JSON.stringify(serviceProvider), { withCredentials: true });
		return response.data;
	  } catch (error) {
		console.error('Error fetching addressId:', error);
		throw error; // You might want to handle the error differently in your application
	  }
}

const SearchBeneficiaries = async(text) => {
	const apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/beneficiaries';
	const searchText =  encodeURIComponent(text);

	try {
		const response = await axios.get(apiUrl+"?searchText="+searchText, { withCredentials: true });
		return response.data;
	} catch(error) {
		console.error("Error in search beneficiaries:", error);
		throw error;
	}
} 

const SearchSP = async(text) => {
	const apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/serviceproviders';
	const searchText =  encodeURIComponent(text);

	try {
		const response = await axios.get(apiUrl+"?searchText="+searchText, { withCredentials: true });
		return response.data;
	} catch(error) {
		console.error("Error in search SP:", error);
		throw error;
	}
}
const GetNotifications = async (entityType, individualId) => {

	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/notifications';
	try {

		const response = await axios.get(`${apiUrl}/${entityType}/${individualId}`, { withCredentials: true });
		return response.data
	} catch (error) {

		console.log("Error in searching Notification:", error);
		throw error;
	}
}
const UpdateNotification = async (entityType, notificationId, notificationData) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/notification';
	try {
		const response = await axios.put(`${apiUrl}/${entityType}/${notificationId}`, JSON.stringify(notificationData), { withCredentials: true });
		return response.data
	} catch (error) {
		console.log("Error in updating Notification:", error);
		throw error;
	}
}

const GetJustWhereUsers= async(emails) => {
	const apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/contacts';
	try {
		const obj = {
			"userIds" : emails
		  }
		const response = await axios.post(`${apiUrl}`, JSON.stringify(obj), { withCredentials: true });
		return response.data;
	
	} catch(error) {
		console.error('Error creating account:', error);
		throw error;
	}
}
const DeleteNotification=async(entityType, notificationId)=>{
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/notification';
	try {
		const response = await axios.delete(`${apiUrl}/${entityType}/${notificationId}`);
		console.log("The response is :",response)
		return response
	} catch (error) {
		console.log("Error in deleting Notification:", error);
		throw error;
	}
}

const UpdateIndividualPrivateSP = async (individualId, serviceProviders) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/individuals';

	try {
		const obj = {
			"newprivateserviceproviders" : serviceProviders
		  }
		const response = await axios.put(`${apiUrl}/${individualId}`, JSON.stringify(obj), { withCredentials: true });
		return response.data;
	} catch (error) {
		console.error('Error updating individual', error);
		throw error; // You might want to handle the error differently in your application
	}
};

const GetSecuredContents = async () => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/securedcontents';
	try {
	  const response = await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching secured contents:', error);
	  throw error; 
	}
}

const GetSecuredContent = async(id) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/securedcontents/';
	apiUrl += id;
	try {
	  const response =  await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error getting secured content' , error);
	  throw error; 
	}
}

const GetSharedSecuredContent = async(id, token, beneficiaryid, individualId ) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/securedcontents/shared/';
	apiUrl += id;
	apiUrl += `?beneficiary_token=${token}`;
	apiUrl += `&beneficiaryId=${beneficiaryid}`;
	apiUrl += `&individualId=${individualId}`;
	try {
	  const response =  await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error getting secured content' , error);
	  throw error; 
	}
}

const CreateSecuredContent = async(content) => {
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/securedcontents';

	try {
		const response =  await axios.post(`${apiUrl}`, JSON.stringify(content), { withCredentials: true });
		return response.data;
	  } catch (error) {
		console.error('Error in create secure content:', error);
		throw error; // You might want to handle the error differently in your application
	  }
}

const UpdateSecuredContent = async(id, content) => {
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/securedcontents/';
	apiUrl += id;

	try {
		const response =  await axios.put(`${apiUrl}`, JSON.stringify(content), { withCredentials: true });
		return response.data;
	  } catch (error) {
		console.error('Error in update secure content:', error);
		throw error; // You might want to handle the error differently in your application
	  }
}

const DeleteSecureContent = async(id) => {
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/securedcontents/';
	apiUrl += id;
	try {
	  const response = await axios.delete(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error deleting secure content:', error);
	  throw error; // You might want to handle the error differently in your application
	}
}

const GetSharesForSecureContent = async (securedcontentID ) => {
	const apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/records/Securedcontent';
	try {
	  const response = await axios.get(`${apiUrl}/${securedcontentID}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching secure content records:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetSentRequestsFromServiceProvider = async (spid,short ) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests/serviceprovider/from/' + spid ;
	apiUrl += short ? '?short=true' : '';
	try {
	  const response = await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching individualId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetSentRequestsFromBeneficiary = async (benid,short ) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/requests/beneficiary/from/' + benid ;
	apiUrl += short ? '?short=true' : '';
	try {
	  const response = await axios.get(`${apiUrl}`, { withCredentials: true });
	  return response.data;
	} catch (error) {
	  console.error('Error fetching individualId:', error);
	  throw error; // You might want to handle the error differently in your application
	}
};

const GetVisitLabelByToken = async (token) => {
//	/visit/label/token/{token}:
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/visit/label/token/' + token;
	try {
		const response = await axios.get(`${apiUrl}`, { withCredentials: true });
		return response.data;
	  } catch (error) {
		console.error('Error fetching visit label by token:', error);
		throw error; // You might want to handle the error differently in your application
	  }
}

const GetVisitLinkByLabel = async (label) => {
	let apiUrl =  process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/visit/label/' + label;
	try {
		const response = await axios.get(`${apiUrl}`, { withCredentials: true });
		return response.headers.get('Location');
	  } catch (error) {
		console.error('Error fetching visit label:', error);
		throw error; // You might want to handle the error differently in your application
	  }
}

const GetTemplatesByServiceProviderID = async(spid) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + `/templates/serviceprovider/` + spid;
	try {
		const response = await axios.get(`${apiUrl}`, {withCredentials: true});
		return response.data;
	} catch(err) {
		if(err?.response?.status === 404) {
			return [];
		}
		console.error('Error in get templates by service provider id', err);
		throw err;
	}
}

const GetTemplateByID = async(id) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/templates/' + id;
	try {
		const response = await axios.get(`${apiUrl}`, {withCredentials: true});
		return response.data;
	} catch(err) {
		console.error('Error in get templates by id', err);
		throw err;
	}
}


const CreateTemplate = async(template) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/templates' ;
	try {
		template.id = "00000000-0000-0000-0000-000000000000"
		const response = await axios.post(`${apiUrl}`,JSON.stringify(template), {withCredentials: true});
		return response.data;
	} catch(err) {
		console.error('Error in get templates by id', err);
		throw err;
	}
}

const UpdateTemplate = async(id, template) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/templates/' + id;
	try {
		const response = await axios.put(`${apiUrl}`, JSON.stringify(template), {withCredentials: true});
		return response.data;
	} catch(err) {
		console.error('Error in update templates by id', err);
		throw err;
	}
}

const DeleteTemplate = async (id) => {
	let apiUrl = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + '/templates/' + id;
	try {
		const response = await axios.delete(`${apiUrl}`, {withCredentials: true});
		return response.data;
	} catch(err) {
		console.error('Error in delete templates by id', err);
		throw err;
	}
}

export { DeleteBeneficiary, getInfo, GetSharesWith, GetSecondaryTokenForRequest, UpdateRequest, DeleteRequest, ConvertUnixTimestampToDateString, GetRequestDetails, CreateAddressRequestTo, GetSentRequestsFrom, GetReceivedRequestsFor, FetchIndividual, GetAddress, GetShares, UpdateAddress, AddAddress, GetSharesForAddress, SetupInterceptor, DeleteSecondaryToken, DeleteAddress, GetAccounts, CreateAccount, DeleteAccount, GetAccount, UpdateAccount, GetServiceProvider, UpdateServiceProvider, DeletePrimaryToken, CreatePrimaryToken, CreateSecondaryToken, GetBeneficiary, UpdateBeneficiary, SearchBeneficiaries, SearchSP, GetSharesWithServiceProvider,GetNotifications,UpdateNotification, GetJustWhereUsers,DeleteNotification, UpdateIndividualPrivateSP, DeleteServiceProvider, GetSecuredContents, GetSharedSecuredContent, GetSecuredContent, UpdateSecuredContent, DeleteSecureContent, CreateSecuredContent, GetSharesForSecureContent, GetSentRequestsFromBeneficiary, GetSentRequestsFromServiceProvider, GetVisitLabelByToken, GetVisitLinkByLabel, GetTemplateByID, GetTemplatesByServiceProviderID, CreateTemplate, UpdateTemplate, DeleteTemplate };
