import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeModal = ({ isOpen, onClose, qrValue }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>QR Code</h2>
        <QRCodeCanvas value={qrValue} size={200} />
        <button className="close-button mt-1 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-red-500" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default QRCodeModal;
